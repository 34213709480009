<template>
    <div class="container pt-16 px-2">
      <h1 class="text-red text-lg mb-6">
        Failed Payments
      </h1>

      <LinkTemplate :to="{path:'/failed-payments/create'}" class="my-6">
            Create New
        </LinkTemplate>

      <div class="">
        <TextInput @input="maybeSearch" type="text" label="Search" v-model="search" :required="false" ></TextInput>
      </div>

      <div v-if="items.length" class="">
        <TableTemplate v-if="loading == false" :candelete="false" :columns="columns" :items="items"></TableTemplate>
        <img v-else class="m-auto my-4" style="width:50px;height:50px;" src="/assets/loading.gif" alt="Loading search submission">
        <Pagination @navigate="navigate" :count="count" :limit="limit" :page="page"></Pagination>
      </div>
      <div v-else class="">
        <TableTemplate v-if="loading == false" :candelete="false" :columns="columns" :items="pages[page]"></TableTemplate>
        <img v-else class="m-auto my-4" style="width:50px;height:50px;" src="/assets/loading.gif" alt="Loading search submission">
        <Pagination @navigate="navigate" :count="count" :limit="limit" :page="page"></Pagination>
      </div>
    </div>
</template>

<script>
import List from '../../../mixins/List'
import moment from 'moment'
export default {
    name: 'failedPayments',
    mixins: [List],
      data: () => ({
        limit: 10,
        page: 0,
        action: '/failed_payments',
        searchParams: {
            'model': 'FailedPayment',
            'fields': ['account_id','name','email','subscription_id', 'failed_amount'],
        },
        columns:[
          {
            id:'subscription_id',
            label:'Subscription',
            html_custom: 'subscriptionLink', 
          },
          {
            id:'created_at',
            label:'Created',
            parse_date: true
          },
          {
            id:'rebill_successful',
            label:'Rebilled?',
          },
          {
            id:'failed_amount',
            label:'Amount',
          },
          {
            id:'name',
            label:'Name',
          },
          {
            id:'account_id',
            label:'Account',
            html_custom: 'accountLink', 
          }
        ],
    }),
    computed: {

    },
    props: [],
    methods: {
        navigate(index) {
            if(index > -1 && index <= Math.floor(this.count/10)) {
                this.page = index
                this.getItems()
            }
        },
        subscriptionLink(item) {
          let html = ''
          html += `<a class="text-indigo-600 hover:text-indigo-900" href="/subscriptions/${item.subscription_id}/" >${item.subscription_id}</a>`
          return html
        },
        accountLink(item) {
          let html = ''
          html += `<a class="text-indigo-600 hover:text-indigo-900" href="/agencies/${item.account_id}/" >${item.account_id}</a>`
          return html
        },
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>

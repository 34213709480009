<template>
    <div class="pt-16 px-2 ">
        <h1 class="text-red text-lg mb-6">
            Create Failed Payment
        </h1>
        <div>
            <FormTemplate @response="formResponse" button="Create" method="post" action="/failed_payments/create" :formdata="formData">
                <SearchSelectInput @item:removed="subscriptionRemoved" @item:selected="subscriptionSelected" :params="subscriptionSearchParams" :multiple="false" label="Subscription" v-model="item.subscription" :optiondisplay="subscriptionDisplay"></SearchSelectInput>
                <TextInput type="number" :required="true" v-model="item.failed_amount" label="Failed Amount"/>
                <Checkbox label="Disallow Automated Billing" v-model="item.legacy_payment"></Checkbox>
                <Checkbox label="Send Email" v-model="item.send_email"></Checkbox>
            </FormTemplate>
        </div>
    </div>
</template>

<script>
import Create from '../../../mixins/Create'
import Swal from 'sweetalert2'

export default {
    name: 'CreateFailedPayment',
    mixins: [Create],
    data: () => ({
        back: '/failed-payments',
        item: {
            subscription: false,
            failed_amount: false,
            legacy_payment: false,
            send_email: true
        },
        subscriptionSearchParams: {
            'model': 'Subscription',
            'fields': ['id','uid'],
            'action': '/search/',
        },
    }),
    computed: {
        formData() {
            return {
                'failed_payment':this.item,
            }
        },
    },
    props: [],
    methods: {
        subscriptionDisplay(item) {
            return 'ID: ' + item.id;
        },
        subscriptionSelected(evt) {
            this.item.subscription = evt.item

            if ( this.item.subscription.remediation_transaction_id ) {
                Swal.fire({
                    title: 'This subscription currently has a remediated transaction present',
                    text: "Are you sure you want to create another failed payment?",
                    showCancelButton: false,
                    showDenyButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes',
                    denyButtonText: 'No'
                }).then((result) => {
                    if ( result.isDenied ) {
                        this.$router.push({path: '/failed-payments'})
                    }
                })
            }
        },
        subscriptionRemoved() {
            this.item.subscription = false
        },
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>

<template>
    <div id="failed-payments" class="w-full">
        <router-view/>
    </div>
    </template>
    
    <script>
    
    export default {
        name: 'failedPayments',
        data: () => ({
        }),
        computed: {
        },
        props: [],
        methods: {
        },
        watch: {
        },
        created() {
        },
        beforeDestroy() {
        },
        mounted() {
        },
        components: {
        },
    }
    </script>
    <style scoped>
    </style>
    